<template>
    <div id="autoCalibrationTagsLocation" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="autoCalibrationTagsLocation"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="act_autoCalibrationTagsLocationSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <div class="dropdown displayInlineBlock">
                                        <button
                                            class="btn btn-bold btn-brand btn-sm"
                                            type="button"
                                            @click="onClickNeedHelp"
                                        >
                                            {{ $t("common_needHelp") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="autocalibrationTagsLocation" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-lg-4">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("act_autocalibrationtagsLocation") }}
                                                </h3>
                                            </div>
                                            <div class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-toolbar">
                                                    <div class="kt-portlet__head-actions">
                                                        <a id="toggleAutoCalibrationTagFilterButton" v-if="siteAutoCalibrationTags && siteAutoCalibrationTags.length > 0 && currentAutoCalibrationTagLocation.state !== 'START_MEASURE'" href="#" @click="toggleFilter" :class="{ 'btn-brand': isFilterDisplayed }" class="btn btn-outline-brand btn-sm btn-icon btn-icon-md">
                                                            <i class="la la-filter"></i>
                                                        </a>
                                                    </div>
                                                    <template v-if="currentAutoCalibrationTagLocation.state === 'START_MEASURE'">
                                                        <a id="cancelMeasureAutoTagsButton" href="#" @click="onCancelMeasure" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="marginL5 btn btn-sm btn-outline-dark btn-bold">
                                                            <span id="cancelMeasureAutoTagsLabel">{{ $t("common_cancel") }}</span>
                                                        </a>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <!--begin emptyList component-->
                                            <app-emptylist targetObjectName="AUTOCALIBRATION_TAG" mainTextKey="act_notFound" subTextKey="act_notFoundSubLabel" imgName="empty_autocalibrationtag.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                            <!--end emptyList component-->

                                            <span v-if="siteAutoCalibrationTags && siteAutoCalibrationTags.length == 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteAutoCalibrationTags.length }} {{ $t("asset_assetTag") }}</span>
                                            <span v-else-if="siteAutoCalibrationTags && siteAutoCalibrationTags.length > 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteAutoCalibrationTags.length }} {{ $t("asset_assetTags") }}</span>

                                            <div class="form-group" v-if="filteredAutocalibrationTagsBySearch">
                                                <div v-if="isFilterDisplayed" id="autoCalibrationTagsFilter">
                                                    <app-multiselect
                                                        v-model="filtersSelected"
                                                        v-on:close="updateScrollbar"
                                                        v-on:open="updateScrollbarFilterOpen"
                                                        :options="filtersOptions"
                                                        :multiple="true"
                                                        group-values="items"
                                                        group-label="type"
                                                        :group-select="true"
                                                        v-bind:placeholder="$t('act_filterBy')"
                                                        track-by="name"
                                                        label="name"
                                                        :selectLabel="$t('common_multiselectSelectLabel')"
                                                        :selectGroupLabel="$t('common_multiselectSelectGroupLabel')"
                                                        :selectedLabel="$t('common_multiselectSelectedLabel')"
                                                        :deselectLabel="$t('common_multiselectDeselectLabel')"
                                                        :deselectGroupLabel="$t('common_multiDeselectGroupLabel')"
                                                    >
                                                        <span slot="noResult">{{$t("common_multiselectNoFound")}}</span>
                                                    </app-multiselect>
                                                </div>
                                                <div v-if="siteAutoCalibrationTags && siteAutoCalibrationTags.length !== 0" class="form-group">
                                                    <div class="kt-input-icon kt-input-icon--left" style="margin-top: -10px; margin-bottom: -10px;">
                                                        <input v-model="searchTextValue" type="text" class="form-control" :placeholder="$t('common_searchBy')" id="actlocation_searchInput" />
                                                        <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                            <span><i class="la la-search"></i></span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-content">
                                                <div class="tab-pane active" id="kt_widget4_tab1_content">
                                                    <div id="actListWithScrollbar" v-if="filteredAutocalibrationTagsBySearch.length > 0" class="kt-scroll" data-scroll="true" data-height="600">
                                                        <div class="kt-widget4" id="autoCalibrationTagsList">
                                                            <div v-for="(actag, index) in filteredAutocalibrationTagsBySearch" :key="actag.id" class="kt-widget4__item actLine" :class="index === filteredAutocalibrationTagsBySearch.length - 1 ? 'kt-margin-b-30' : ''">
                                                                <div @click="onHighlight" :data-id="actag.id" v-bind:class="getHighlightListClass(actag)" class="kt-widget4__info">
                                                                    <a v-if="actag.serialNumber" :id="actag.serialNumber" @click="onHighlight" :data-id="actag.id" v-bind:class="getHighlightClass(actag)" href="#" class="highlightActagLocationLink kt-widget4__username">
                                                                        {{ actag.serialNumber }}
                                                                    </a>
                                                                    <a v-else :id="actag.macAddress" @click="onHighlight" :data-id="actag.id" v-bind:class="getHighlightClass(actag)" href="#" class="highlightActagLocationLink kt-widget4__username">
                                                                        {{ actag.macAddress }}
                                                                    </a>

                                                                    <p v-if="actag.serialNumber" class="kt-widget4__text colorSubText">{{ actag.macAddress }}</p>
                                                                    <p v-else class="kt-widget4__text colorSubText">{{ actag.serialNumber }}</p>

                                                                    <p v-if="actag.location && actag.location.type" class="kt-widget4__text colorSubText">{{ actag.building.name }} / {{ actag.floor.name }} ({{ $t("b&f_level") }}: {{ actag.floor.floorNumber }})</p>
                                                                    <p v-if="actag.location && actag.location.type" class="kt-widget4__text colorSubText">Lat, Long: {{ actag.location.coordinates[1] }}, {{ actag.location.coordinates[0] }}</p>
                                                                </div>

                                                                <a :id="'actTag_setLocation_' + actag.macAddress" v-if="(!actag.location || !actag.location.type) && (currentAutoCalibrationTagLocation.id === '' || currentAutoCalibrationTagLocation.state == 'HIGHLIGHT') && currentAutoCalibrationTagLocation.state !== 'START_MEASURE' && !currentSite.isInProduction" @click="onSetLocation" :data-id="actag.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('act_setActLocationOnTheFloorMap')" href="#" class="setActagLocationBtn btn btn-sm btn-outline-brand btn-bold"> <i class="la la-map-marker notYetLocatedIcon"></i> {{ $t("common_setLocation") }} </a>
                                                                <button v-if="(!actag.location || !actag.location.type) && (currentAutoCalibrationTagLocation.id === '' || currentAutoCalibrationTagLocation.state == 'HIGHLIGHT') && currentAutoCalibrationTagLocation.state !== 'START_MEASURE' && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeActagLocationDisabledButton btn btn-sm btn-outline-brand btn-bold">
                                                                    <i class="la la-map-marker notYetLocatedIcon"></i>
                                                                    {{ $t("common_setLocation") }}
                                                                </button>

                                                                <a :id="'actTag_cancelLocation_' + actag.macAddress" v-if="(!actag.location || !actag.location.type) && actag.id === currentAutoCalibrationTagLocation.id && !currentSite.isInProduction" @click="onCancelLocation" :data-id="actag.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('act_cancelAction')" class="cancelActagLocationButton btn btn-sm btn-outline-dark btn-bold"> {{ $t("common_cancel") }} </a>
                                                                <button v-if="(!actag.location || !actag.location.type) && actag.id === currentAutoCalibrationTagLocation.id && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeActagLocationDisabledButton btn btn-sm btn-outline-dark btn-bold">
                                                                    {{ $t("common_cancel") }}
                                                                </button>

                                                                <template v-if="actag.location && actag.location.type && currentAutoCalibrationTagLocation.id === actag.id && currentAutoCalibrationTagLocation.state == 'EDIT'">
                                                                    <a :id="'actTag_cancelEditLocation_' + actag.macAddress" @click="onCancelEditLocation" :data-id="actag.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('act_cancelAction')" class="cancelEditActagLocationButton alreadyLocatedLink btn btn-sm btn-outline-dark btn-bold"> {{ $t("common_cancel") }} </a>
                                                                    <a :id="'actTag_saveEditLocation_' + actag.macAddress" @click="onSaveEditLocation" :data-id="actag.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('act_saveEditActLocation')" class="saveEditActagLocationButton btn btn-sm btn-outline-dark btn-bold"> <i class="la la-save notYetLocatedIcon"></i> {{ $t("common_save") }} </a>
                                                                </template>

                                                                <template v-if="actag.location && actag.location.type && (currentAutoCalibrationTagLocation.id === '' || currentAutoCalibrationTagLocation.state == 'HIGHLIGHT') && currentAutoCalibrationTagLocation.state !== 'START_MEASURE' && currentAutoCalibrationTagLocation.state !== 'CREATE'">
                                                                    <a :id="'actTag_highlight_' + actag.macAddress" @click="onHighlight" :data-id="actag.id" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('act_highlightActLocation')" class="highlightActagLocationButton alreadyLocatedLink btn btn-sm btn-outline-success btn-bold" href="#">
                                                                        <i class="la la-crosshairs alreadyLocatedIcon"></i>
                                                                    </a>

                                                                    <div class="editActagDropdown btn-group dropleft show" data-toggle="kt-tooltip" :data-original-title="!currentSite.isInProduction && $t('act_editActLocation')" data-placement="top">
                                                                        <a v-if="!currentSite.isInProduction" :id="'actTag_editDropdown_' + actag.macAddress" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" class="editActagDropdownButton actListLink btn btn-sm btn-outline-warning btn-bold">
                                                                            <i class="la la-pencil actListIcon"></i>
                                                                        </a>
                                                                        <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled editActagDropdownButton actListLink btn btn-sm btn-outline-warning btn-bold">
                                                                            <i class="la la-pencil actListIcon"></i>
                                                                        </button>
                                                                        <div x-placement="left-start" class="dropdown-menu" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-184px, 0px, 0px);">
                                                                            <a :id="'actTag_editLocationItem_' + actag.macAddress" @click="onEditACTLocation" :data-id="actag.id" href="#" class="editActagLocationItem dropdown-item">{{ $t("common_edit_location") }}</a>
                                                                            <a :id="'actTag_editFloorElevation_' + actag.macAddress" @click="onEditACTFloorElevation" :data-id="actag.id" href="#" class="editActagFloorElevationItem dropdown-item">{{ $t("common_edit_floor_elevation") }}</a>
                                                                        </div>
                                                                    </div>

                                                                    <a v-if="!currentSite.isInProduction" :id="'actTag_remove_' + actag.macAddress" @click="onDeleteLocation" :data-id="actag.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('act_removeActLocation')" class="removeActagLocationButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove alreadyLocatedIcon"></i>
                                                                    </a>
                                                                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeActagLocationDisabledButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove alreadyLocatedIcon"></i>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>

                                <div class="col-lg-8">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <app-locationmap ref="locationmap" mode="autocalibrationtagsLocation"></app-locationmap>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal -->
                    <app-importautocalibrationtagsfromXLSmodal ref="importAutocalibrationTagsModal"></app-importautocalibrationtagsfromXLSmodal>
                    <app-autocalibrationpositionmodal></app-autocalibrationpositionmodal>

                    <app-autocalibrationtagsmodal></app-autocalibrationtagsmodal>
                    <!-- end:: Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import SiteTitle from "../site/widgets/sitetitle.vue";
import LocationMap from "../map/locationmap.vue";
import EmptyList from "./../utils/emptylist.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import AutoCalibrationPositionModal from "../modals/autocalibrationtagspositionmodal.vue";
import AutoCalibrationTagsModal from "../modals/autocalibrationtagsmodal.vue";
import autocalibrationtagsVue from './autocalibrationtags.vue';
import commonVueHelper from '../../helpers/commonVueHelper';
import ImportAutocalibrationTagsModal from "../modals/importautocalibrationtagsmodal.vue";

function updateScrollbar() {
    let actListScrollbar = $("#actListWithScrollbar");
    let offset = 185;
    if (actListScrollbar) {
        // timeout is used to be sure to get the current map height
        setTimeout(() => {
            let mapHeight = $("#map").height();
            actListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
            actListScrollbar.attr("data-height", $("#map").height() - offset);
            KTApp.init();
            KTLayout.init();
        }, 100);
    }
}

$(window).on("resize", () => {
    updateScrollbar();
});

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            localAutocalibrationTags: [],
            searchTextValue: "",
            isEmptyList: false,
            isMounted: false,
            filtersSelected: [],
            filtersOptions: [],
            isFilterDisplayed: false
        };
    },
    created: function() {
        console.log("Component(AutocalibrationTagsLocation)::created() - called");
        // Reset current autocalibration tag location to empty by default
        this.setCurrentAutoCalibrationTagLocation({ id: "", state: "" });
        
        this.getSiteIndoorAreas(this.siteId);
        this.getSiteWalls(this.siteId);
        this.getSiteBLEGateways(this.siteId);
        this.getSiteAutoCalibrationTags(this.siteId);
        this.getSiteGeofences(this.siteId);
    },
    mounted: function() {
        console.log("Component(AutocalibrationTagsLocation)::mounted() - Init metronic layout");
        this.isMounted = true;
        updateScrollbar();
        this.getWallTypes();
    },
    destroyed: function() {
        console.log("Component(AutocalibrationTagsLocation)::destroyed() - called");
        this.setIndoorAreasLayerDisplayed(false);
        this.setGeofencesLayerDisplayed(false);
        this.setGatewaysLayerDisplayed(false);
        this.setAutocalibrationTagsLayerDisplayed(false);
        this.setWallsLayerDisplayed(false);
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteAutoCalibrationTags() {
            if (this.siteAutoCalibrationTags) {
                this.localAutocalibrationTags = this.siteAutoCalibrationTags;
                if (this.siteAutoCalibrationTags.length == 0) {
                    this.isEmptyList = true;
                } else {
                    this.isEmptyList = false;
                }

                // add filter by located / not located
                let filterItems = [
                    {
                        type: "location",
                        id: "notLocated",
                        name: i18n.t("act_notLocatedAutoCalibrationTags")
                    },
                    {
                        type: "location",
                        id: "located",
                        name: i18n.t("act_locatedAutoCalibrationTags")
                    }
                ];
                this.updateFiltersOptionsElement("--- " + i18n.t("act_autoCalibrationTagsLocation"), filterItems);

                var showModal = true;
                if (this.siteAutoCalibrationTags.length > 0){
                    this.siteAutoCalibrationTags.forEach(autocalibrationtag => {
                        if(autocalibrationtag.floor) {
                            showModal = false;
                        }
                    });
                    if (showModal) {
                        $("#autocalibrationPositionModal").modal("show");
                    }
                }

                // Now show gateway highlight
                if (this.$route.params && this.$route.params.highlight) {
                    // Come from siteoverview page to see location of autotag
                    // Query hightlight value is beacon id
                    const beaconId = this.$route.params.highlight;
                    commonVueHelper.initToolTips();
                    setTimeout(()=> {
                        this.setCurrentAutoCalibrationTagLocation({ id: beaconId, floorElevation: this.getACTagFloorElevation(beaconId), state: "HIGHLIGHT" });
                    }, 10);
                }

            }
        },

        buildingsAndTheirFloorsArray(buildingsArray) {
            this.getSiteAutoCalibrationTags(this.siteId);
            if (buildingsArray && buildingsArray.length !== 0) {
                let buildingsFilter = {
                    type: "--- " + i18n.t("b&f_buildings"),
                    items: []
                };
                let floorsFilter = {
                    type: "--- " + i18n.t("b&f_floors"),
                    items: []
                };
                let building = null;
                let floor = null;
                for (let i in buildingsArray) {
                    if (buildingsArray.hasOwnProperty(i)){
                        building = buildingsArray[i];
                        buildingsFilter.items.push({
                            type: "building",
                            name: building.name,
                            id: building.id
                        });
                        if (building.floors && building.floors.length !== 0) {
                            for (let j in building.floors) {
                                if (building.floors.hasOwnProperty(j)) {
                                    floor = building.floors[j];
                                    floorsFilter.items.push({
                                        type: "floor",
                                        name: floor.name,
                                        id: floor.id
                                    });
                                }
                            }
                        }
                    }
                }
                this.updateFiltersOptionsElement(buildingsFilter.type, buildingsFilter.items, true);
                this.updateFiltersOptionsElement(floorsFilter.type, floorsFilter.items, true);
            }
        },

        currentAutoCalibrationTagLocation() {
            // Scroll only if highlight comes from the click on a marker inside the map
            if (this.currentAutoCalibrationTagLocation.state === "HIGHLIGHT" && this.currentAutoCalibrationTagLocation.isHighlightFromMap) {
                var element = $(".highlightActagLocationLink[data-id='" + this.currentAutoCalibrationTagLocation.id + "']");
                $("#actListWithScrollbar").scrollTop(0);
                $("#actListWithScrollbar").animate({scrollTop: element.offset().top - 350}, 600);
            }
        },

        isFilterDisplayed() {
            this.updateScrollbar();
        },

        filtersSelected() {
            this.updateScrollbar();
        },
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteAutoCalibrationTags", "currentSite", "currentAutoCalibrationTagLocation", "getAutocalibrationTagById", "buildingsAndTheirFloorsArray"]),

        // Computed property used to filter
        filteredAutocalibrationTags: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                updateScrollbar();
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            var self = this;
            if (!self.searchTextValue) {
                return this.localAutocalibrationTags;
            } else {
                let filtered = this.localAutocalibrationTags.filter(function(actTag) {
                    return actTag.macAddress.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || (actTag.serialNumber && actTag.serialNumber.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (actTag.building && actTag.building.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (actTag.floor && actTag.floor.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1);
                });
                return filtered;
            }
        },

        filteredAutocalibrationTagsBySearch: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                updateScrollbar();
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            var self = this;
            let filterAutoCalibrationTags = null;
            if (!this.currentSite) {
                return [];
            }
            if (!self.searchTextValue) {
                filterAutoCalibrationTags = this.localAutocalibrationTags;
            } else {
                filterAutoCalibrationTags = this.localAutocalibrationTags.filter(function(actTag) {
                    return actTag.macAddress.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || (actTag.serialNumber && actTag.serialNumber.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (actTag.building && actTag.building.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (actTag.floor && actTag.floor.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1);
                });
            }
            // 2- Apply filters as selected in filters list
            if (this.filtersSelected && this.filtersSelected.length !== 0) {
                // Some filters were positioned, let's filter gateways accordingly

                // Flatten filtersSelected array
                let flattenedFilters = {};
                let filter = null;
                for (let item in this.filtersSelected) {
                    if (this.filtersSelected.hasOwnProperty(item)){
                        filter = this.filtersSelected[item];
                        if (!flattenedFilters[filter.type]) {
                            flattenedFilters[filter.type] = [];
                        }
                        switch (filter.type) {
                            case "building":
                            case "floor":
                            case "location":
                                flattenedFilters[filter.type].push(filter.id);
                                break;
                            case "keyword":
                                flattenedFilters[filter.type].push(filter.name);
                                break;
                        }
                    }
                }
                // apply location filter
                if (flattenedFilters.location) {
                    let displayLocated = _.includes(flattenedFilters.location, "located");
                    let displayNotLocated = _.includes(flattenedFilters.location, "notLocated");
                    let tmpArray = filterAutoCalibrationTags.filter(function(gateway) {
                        if (gateway.location && gateway.location.type && displayLocated) return true;
                        else if ((!gateway.location || !gateway.location.type) && displayNotLocated) return true;
                        else return false;
                    });
                    filterAutoCalibrationTags = tmpArray;
                }
                // apply buildings filter
                if (flattenedFilters.building) {
                    let tmpArray = filterAutoCalibrationTags.filter(function(gateway) {
                        return gateway.building != null && _.includes(flattenedFilters.building, gateway.building.id);
                    });
                    filterAutoCalibrationTags = tmpArray;
                }
                // apply floors filter
                if (flattenedFilters.floor) {
                    let tmpArray = filterAutoCalibrationTags.filter(function(gateway) {
                        return gateway.floor != null && _.includes(flattenedFilters.floor, gateway.floor.id);
                    });
                    filterAutoCalibrationTags = tmpArray;
                }
                // apply keywords filter
                if (flattenedFilters.keyword) {
                    let tmpArray = filterAutoCalibrationTags.filter(function(gateway) {
                        if (gateway.keywords === null) return false;
                        for (let keyword in gateway.keywords.list) {
                            if (_.includes(flattenedFilters.keyword, gateway.keywords.list[keyword])) {
                                return true;
                            }
                        }
                        return false;
                    });
                    filterAutoCalibrationTags = tmpArray;
                }
            }

            return filterAutoCalibrationTags;
        },

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "getSiteAutoCalibrationTags",
            "setCurrentAutoCalibrationTagLocation",
            "setIndoorAreasLayerDisplayed",
            "setGeofencesLayerDisplayed",
            "setGatewaysLayerDisplayed",
            "setAutocalibrationTagsLayerDisplayed",
            "setWallsLayerDisplayed",
            "getSiteIndoorAreas",
            "getSiteWalls",
            "getSiteBLEGateways",
            "getWallTypes",
            "getSiteAutoCalibrationTags",
            "getSiteGeofences"
        ]),

        toggleFilter() {
            this.isFilterDisplayed = !this.isFilterDisplayed;
        },

        updateScrollbar() {
            if (this.siteAutoCalibrationTags && this.siteAutoCalibrationTags.length > 0) {
                let autoCalibrationTagListScrollbar = $("#actListWithScrollbar");
                if (autoCalibrationTagListScrollbar) {
                    // timeout is used to be sure to get the current map height
                    setTimeout(() => {
                        let offset = this.isFilterDisplayed ? 190 + $("#autoCalibrationTagsFilter").height() : 185;

                        let mapHeight = $("#map").height();
                        autoCalibrationTagListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                        autoCalibrationTagListScrollbar.attr("data-height", $("#map").height() - offset);
                        KTApp.init();
                        KTLayout.init();
                    }, 100);
                }
            }
        },

        updateScrollbarFilterOpen() {
            let autoCalibrationTagListScrollbar = $("#actListWithScrollbar");
            if (autoCalibrationTagListScrollbar) {
                let offset = this.filtersSelected.length > 0 ? 230 + $("#autoCalibrationTagsFilter").height() : 185 + $("#autoCalibrationTagsFilter").height();

                let mapHeight = $("#map").height();
                autoCalibrationTagListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                autoCalibrationTagListScrollbar.attr("data-height", $("#map").height() - offset);
                KTApp.init();
                KTLayout.init();
            }
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightClass(actag) {
            return {
                cursorDefault: !actag.location || !actag.location.type,
                highlightedName: this.currentAutoCalibrationTagLocation.id === actag.id,
                colorMainText: this.currentAutoCalibrationTagLocation.id !== actag.id
            };
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightListClass(actag) {
            return {
                cursorPointer: actag.location && actag.location.type,
                highlightedItemList: this.currentAutoCalibrationTagLocation.id === actag.id
            };
        },

        onSetLocation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            this.setCurrentAutoCalibrationTagLocation({ id: id, floorElevation: this.getACTagFloorElevation(id), state: "CREATE" });
        },

        onClickNeedHelp() {
            $("#autocalibrationPositionModal").modal("show");
        },

        onHighlight(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            // Get autocalibration tag by id
            let actObj = this.getAutocalibrationTagById(id);
            if (actObj.location && actObj.location.type) {
                this.setCurrentAutoCalibrationTagLocation({ id: id, floorElevation: this.getACTagFloorElevation(id), state: "HIGHLIGHT" });
            }
        },

        onCancelMeasure() {
            console.log("Component(AutocalibrationTagsLocation)::onCancelMeasure - Cancel measure button clicked.");
            if (this.currentAutoCalibrationTagLocation.state === "START_MEASURE") {
                this.setCurrentAutoCalibrationTagLocation({ id: "", state: "CANCEL_MEASURE" });
            }
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        },

        onSaveEditLocation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            this.setCurrentAutoCalibrationTagLocation({ id: id, state: "SAVE_EDIT" });
        },

        onEditACTLocation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            this.setCurrentAutoCalibrationTagLocation({ id: id, floorElevation: this.getACTagFloorElevation(id), state: "EDIT" });
        },

        // This method is called when user click ton "Save" button after edit location of one actag
        onEditACTFloorElevation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            let actag = this.getAutocalibrationTagById(id);
            this.setCurrentAutoCalibrationTagLocation({ id: id, floorElevation: Number(actag.floorElevation), location: actag.location, state: "SET_FLOOR_ELEVATION" });
        },

        // This method is called when user click ton "Save" button after edit location of one actag
        onCancelEditLocation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            this.setCurrentAutoCalibrationTagLocation({ id: id, floorElevation: this.getACTagFloorElevation(id), state: "CANCEL_EDIT" });
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        },

        onDeleteLocation(event) {
            let target = event.currentTarget;
            let id = $(target).attr("data-id");
            this.setCurrentAutoCalibrationTagLocation({ id: id, state: "DELETE" });
        },

        onCancelLocation() {
            this.setCurrentAutoCalibrationTagLocation({ id: "", state: "CANCEL" });
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        },

        getACTagFloorElevation(id) {
            let floorElevation = 2.5; // Default value in backend AutoCalibrationTag model
            if (id) {
                let actagObj = this.getAutocalibrationTagById(id);
                if (actagObj) {
                    floorElevation = Number(actagObj.floorElevation);
                }
            }
            return floorElevation;
        },

        updateFiltersOptionsElement(filterType, filterItems, toPush) {
            const filterTypeFound = this.filtersOptions.find(element => element.type === filterType);
            if (filterTypeFound) {
                // Replace element {filterType: xxx} by {filterType: filterItems}
                this.filtersOptions = _.map(this.filtersOptions, element => {
                    if (element.type === filterType) {
                        element.items = filterItems;
                    }
                    return element;
                });
            } else {
                if (toPush) {
                    // Add element to the end of the filtersOptions array
                    this.filtersOptions.push({
                        type: filterType,
                        items: filterItems
                    });
                } else {
                    // Add element to the beginning of the filtersOptions array
                    this.filtersOptions.unshift({
                        type: filterType,
                        items: filterItems
                    });
                }
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap,
        "app-emptylist": EmptyList,
        "app-accesserrorportlet": AccessErrorPortlet,
        "app-autocalibrationtagsmodal": AutoCalibrationTagsModal,
        "app-autocalibrationpositionmodal": AutoCalibrationPositionModal,
        "app-importautocalibrationtagsfromXLSmodal": ImportAutocalibrationTagsModal
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.alreadyLocatedIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}
.notYetLocatedIcon {
    margin-top: -4px;
}
.colorMainText {
    color: #5c7cf7 !important;
}
.colorSubText {
    color: #5a6077 !important;
}
.cursorPointer {
    cursor: pointer;
}
.cursorDefault {
    cursor: default;
}
.font500 {
    font-weight: 500;
}
.list-top-badge {
    margin-top: -10px;
}
.counterTopList {
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    width: fit-content;
}
.actLine a:last-child {
    margin-right: 8px;
}

.actListIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.actListLink {
    margin-right: 3px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}

.removeActagLocationDisabledButton {
    margin-right: 8px;
}

#autoCalibrationTagsFilter {
    margin-top: -15px;
    margin-bottom: 20px;
}

#autoCalibrationTagsFilter >>> .multiselect__input {
    padding-top: 9px;
    font-size: 13px;
    font-weight: 400;
}
#autoCalibrationTagsFilter >>> .multiselect__input::-moz-placeholder {
    color: #a7abc3;
    opacity: 1;
}
#autoCalibrationTagsFilter >>> .multiselect__input:-ms-input-placeholder {
    color: #a7abc3;
}
#autoCalibrationTagsFilter >>> .multiselect__input::-webkit-input-placeholder {
    color: #a7abc3;
}
#autoCalibrationTagsFilter >>> .multiselect__placeholder {
    padding-top: 9px;
    font-size: 13px;
    font-weight: 400;
    color: #a7abc3;
}
#autoCalibrationTagsFilter >>> .multiselect__tags {
    padding-top: 0px;
    border: 1px solid #ebedf2;
}
#autoCalibrationTagsFilter >>> .multiselect__tag {
    margin-top: 7px;
    margin-bottom: 0px;
    background: transparent !important;
    border: 1px solid #5d78ff !important;
    color: #5d78ff !important;
    border-radius: 0px !important;
}
#autoCalibrationTagsFilter >>> .multiselect__tag-icon:focus,
#autoCalibrationTagsFilter >>> .multiselect__tag-icon:hover {
    background: inherit;
}
#autoCalibrationTagsFilter >>> .multiselect__tag-icon:after {
    color: #a7abc3;
    font-size: 20px;
    font-weight: 300;
}

#autoCalibrationTagsFilter >>> .multiselect__option--group,
#autoCalibrationTagsFilter >>> .multiselect__option--group:after {
    font-weight: 0.7;
}
#autoCalibrationTagsFilter >>> .multiselect__option--group.multiselect__option--highlight,
#autoCalibrationTagsFilter >>> .multiselect__option--group.multiselect__option--highlight:after {
    background: #5578eb;
}

#autoCalibrationTagsFilter >>> .multiselect__option,
#autoCalibrationTagsFilter >>> .multiselect__option:after {
    padding: 4px;
    min-height: 25px;
    line-height: 17px;
    font-size: 14px;
}
#autoCalibrationTagsFilter >>> .multiselect__option--highlight,
#autoCalibrationTagsFilter >>> .multiselect__option--highlight:after {
    background: #6f8be7;
}
#autoCalibrationTagsFilter >>> .multiselect__option--selected {
    font-weight: 500;
}
</style>

<style>
.marginTM15 {
    margin-top: -15px;
}
</style>
