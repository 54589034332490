<template>
    <div class="modal fade show" id="autocalibrationtagsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form id="autocalibrationtagForm" class="kt-form" novalidate="novalidate" @submit.prevent="onSubmit">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("common_floorElevationLabel") }}</h5>
                        <button type="button" @click="cancelAutoCalibrationTagsLocation" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group validated">
                            <label for="newAutoCalibrationTagForm_floorElevationInput">{{ $t("common_floorElevationLabel") }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="la la-upload"></i></span>
                                </div>
                                <span class="width80percent">
                                    <input v-model="vFloorElevation" type="text" class="form-control" id="newAutoCalibrationTagForm_floorElevationInput" :placeholder="$t('common_enterFloorElevation')" />
                                </span>
                            </div>
                            <div v-if="!isCorrectValue" class="invalid-feedback">
                                {{ $t("error_invalidDecimal") }}
                            </div>
                            <span class="form-text text-muted">{{ $t("act_autoCalibrationTagFloorElevationExample") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="autocalibrationtagsModalCancelButton" type="button" class="btn btn-secondary" @click="cancelAutoCalibrationTagsLocation" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                        <button id="autocalibrationtagsSubmitButton" :disabled="!isCorrectValue" type="button" @click="submitAutoCalibrationTagsLocation" data-dismiss="modal" class="btn btn-brand trigger-submit">{{ $t("common_save") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import commonVueHelper from '../../helpers/commonVueHelper';
export default {
    data() {
        return {
            vFloorElevation: 2.5, // Default value in backend Gateway model
            isCorrectValue: true
        };
    },
    mounted: function() {
        // initialize touchspin buttons on elevation input field
        $("#newAutoCalibrationTagForm_floorElevationInput")
            .TouchSpin({
                buttondown_class: "btn btn-secondary",
                buttonup_class: "btn btn-secondary",
                verticalbuttons: true,
                verticalup: '<i class="la la-angle-up"></i>',
                verticaldown: '<i class="la la-angle-down"></i>',
                step: 0.01,
                decimals: 2
            })
            .on("change", (e) => {
                this.vFloorElevation = $("#newAutoCalibrationTagForm_floorElevationInput").val();
            });
    },
    watch: {
        vFloorElevation() {
            const regexp = /^\d+(\.\d{1,2})?$/;
            const value = this.vFloorElevation.toString();
            this.isCorrectValue = regexp.test(value);
        },
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentAutoCalibrationTagLocation: function(actag) {
            console.log("Component(AutoCalibrationTagsLocation)::watcher(actag) - called");
            if (actag) {
                if (typeof actag.floorElevation === "number") {
                    this.vFloorElevation = actag.floorElevation;
                } else {
                    this.vFloorElevation = 2.5;
                }
                if (actag.state === "SET_FLOOR_ELEVATION") {
                    $("#autocalibrationtagsModal").modal("show");
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentAutoCalibrationTagLocation", "getSelectedBuildingId", "getSelectedFloorId"])
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    methods: {
        ...mapActions(["setAutoCalibrationTagLocation", "setCurrentAutoCalibrationTagLocation", "storeLastAddedObjectLocation"]),
        submitAutoCalibrationTagsLocation() {
            console.log("Component(AutoCalibrationTagsLocation)::actLocationData() - called");
            let actLocationData = this.currentAutoCalibrationTagLocation;
            actLocationData.floorElevation = Number(this.vFloorElevation);
            actLocationData.siteId = this.$route.params.siteId;
            actLocationData.buildingId = this.getSelectedBuildingId;
            actLocationData.floorId = this.getSelectedFloorId;

            this.setAutoCalibrationTagLocation(actLocationData);

            // Store the last added point in store
            this.storeLastAddedObjectLocation({
                type: "ACTAG",
                id: actLocationData.id,
                lastAddedObjectLocation: actLocationData.location
            });

            $("#autocalibrationtagsModal").modal("hide");
        },
        cancelAutoCalibrationTagsLocation() {
            console.log("Component(AutoCalibrationTagsLocation)::cancelAutoCalibrationTagsLocation() - called");
            this.setCurrentAutoCalibrationTagLocation({ id: "", floorElevation: 2.5, state: "" });
            // Initialisation of tooltips
            commonVueHelper.initToolTips();
        }
    }
};
</script>

<style scoped>
.width80percent {
    width: 90%;
}
</style>
